<template>
  <v-snackbar 
    v-model="show" 
    :color="color" 
    :timeout="timeout"
    centered
    top 
  >
        {{ message }}
  </v-snackbar>
</template>

<script>
export default {
  data () {
    return {
      show: false,
      message: '',
      color: '',
      timeout: 1500
    }
  },

  created () {
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'showSnackbarMessage') {
        this.message = state.snackbar.content
        this.color = state.snackbar.color
        this.show = true
      }
    })
  }
}
</script>