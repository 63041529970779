<template>
  <v-container>
    <v-row justify="center" no-gutters>
      <v-col cols="12"  >
        <v-img v-if="config.banner_img.length > 0" :src="config.banner_img" ></v-img>
        <v-img v-else src="../../assets/img/banner.png" ></v-img>
      </v-col>
    </v-row>
    
    <post-water-fall href="/api/post/feed" :announcement="announcement" :columnPost="columnPost"></post-water-fall>

    <fab-btn add></fab-btn>

  </v-container>
</template>

<script>
  import FabBtn from "../widgets/FabBtn.vue";
  import PostWaterFall from "../widgets/PostWaterFall.vue";
  import axios from "axios";
  import { mapGetters } from 'vuex';

  export default {
    name: 'Home',
    data: () => ({
      announcement: null,
      columnPost: null
    }),

    components: {
      FabBtn,
      PostWaterFall
    },

    computed: {
      ...mapGetters([
        'config',
      ]),
    },

    mounted() {
      this.loadNotice();
      this.loadStar()
    },

    methods: {
      loadNotice() {
        axios.get(`/api/notice/latest`)
        .then((response) => {
          if(response.data.data.length > 0) {
            const data = response.data.data[0];
            this.announcement = {
                id: data.id,
                title: data.title,
                content: data.content,
                date: data.create_time.slice(0, 10),
                like_count: data.like_count,
                comment_count: data.comment_count,
                color: this.config.notice_color ? this.config.notice_color : '#7194DB'
            }
          }
        })
        .catch(function () {})
        .then(function () {
        });
      },

      loadStar() {
        axios.get(`/api/post/star/latest`)
        .then((response) => {
          if(response.data.data.length > 0) {
            const data = response.data.data[0];
            this.columnPost = {
                id: data.id,
                title: data.title,
                content: data.content,
                create_time: data.create_time,
                like_count: data.like_count,
                comment_count: data.comment_count,
                img: data.img,
                imgs: data.imgs ? data.imgs : [],
                user: data.user ? data.user: {}
            }
          }
        })
        .catch(function () {})
        .then(function () {
        });
      },

    },
  };
</script>