<template>
  <v-container>
    <template v-if="searchKeyword.length > 0">
      <v-row>
        <v-col cols="3" v-for="(item, i) in users" :key="i">
          <v-list two-line dense class="py-0">
            <v-list-item :to="`/author/detail/${item.id}`" :ripple="false">
              <v-list-item-avatar >
                  <v-img v-if="item.avatar" :src="item.avatar" ></v-img> 
                  <v-img v-else src="../../assets/icon/feather/user.svg"  width="16" height="16" contain></v-img>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  <div style="color:#58BCB3">{{ item.name }}</div>
                </v-list-item-title>
                <v-list-item-subtitle class=" mt-2">
                  {{ item.area }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>

      <v-row no-gutters v-intersect="loadData" align="center" justify="center" v-if="!finished" class="mt-4">
        <v-progress-circular size="12" width="2" indeterminate color="#9A9A9A"></v-progress-circular>
        <p class="caption text-center mt-4">正在加载更多...</p>
      </v-row>

      <div v-if="finished && users.length == 0" class="mt-2">
        <p class="text--disabled text-center text-caption">暂无相关内容</p>
      </div>
    </template>
    <div v-else class="mt-2">
      <p class="text--disabled text-center text-caption">关键词为空</p>
    </div>
  </v-container>
</template>

<script>
  import axios from "axios";
  import { mapGetters } from 'vuex';

  export default {
    data: () => ({
      users: [],
      page_no: 0,
      page_size: 10,
      loading: false,
      finished: false,
    }),

    computed: {
      ...mapGetters([
        'searchKeyword'
      ])
    },

    beforeRouteUpdate(to, from, next) {
      this.page_no = 0;
      this.finished = false;
      this.users = [];
      this.loadData();
      next();
    },

    mounted() {
       this.$store.commit("searchType", 'user'); 
    },

    created() {
      this.loadData();
    },

    methods: {
      loadData () {
        if (this.loading || this.finished || this.searchKeyword.length == 0 ) return;
        const that = this;

        that.page_no += 1;
        that.loading = true;

        axios.get('/api/search', {
            params: {
              kw: this.searchKeyword,
              type: 1,
              page: that.page_no,
              size: that.page_size,
            },
          })
          .then((response) => {
             if (response.data.code === 0) {
              that.users.push.apply(that.users, response.data.data.items);

              // 加载完成
              if (response.data.data.items.length < that.page_size) {
                that.finished = true;
              }
            } else {
              that.finished = true;
              this.$snackbar.showMessage({ content: response.data.msg })
            }
          })
          .catch(function () {})
          .then(function () {
            that.loading = false;
          });
      },
    },
  };
</script>