<template>
  <v-container>
    <v-list two-line>
      <v-list-item>
        <v-list-item-avatar>
            <v-img v-if="avatar && avatar.length > 0" :src="avatar" ></v-img> 
            <v-img v-else src="../../assets/icon/feather/user.svg"  width="16" height="16" contain></v-img>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>
            <div style="color:#58BCB3">{{ username }}</div>
          </v-list-item-title>
          <v-list-item-subtitle class=" mt-2">
            {{ addr }}
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action v-if="id === uid">
          <div>
            <v-btn
                outlined
                dark
                color="#58BCB3"
                elevation="0"
                @click="profileDialog = true"
              >编辑资料
            </v-btn>

            <v-btn
                outlined
                dark
                color="red"
                elevation="0"
                class="ml-2"
                @click="logout"
              >退出登录
            </v-btn>
          </div>
        </v-list-item-action>
      </v-list-item>
    </v-list>

    <post-water-fall :href="`/api/u/${id}/post`"></post-water-fall>

    <fab-btn add></fab-btn>

    <v-dialog
        v-model="profileDialog"
        persistent
        max-width="400"
        >
        <profile @closeEvent="profileDialog = false"></profile>
    </v-dialog>
  </v-container>
</template>

<script>
  import FabBtn from "../widgets/FabBtn.vue";
  import PostWaterFall from "../widgets/PostWaterFall.vue";
  import Profile from "../widgets/Profile.vue";
  import axios from "axios";
  import { mapGetters } from 'vuex';

  export default {
    name: 'author',
    data: () => ({
      avatar: '',
      username: '',
      addr: '',
      profileDialog: false
    }),

    components: {
      FabBtn,
      PostWaterFall,
      Profile
    },

    computed: {
      ...mapGetters([
        'uid',
      ]),
    },

    created() {
        this.id = parseFloat(this.$route.params.id);
        this.loadUser();
    },

    beforeRouteUpdate(to, from, next) {
        this.id = parseFloat(to.params.id);
        this.loadUser();
        next();
    },


    methods: {
      loadUser() {
        axios.get(`/api/u/${this.id}`)
          .then((response) => {
              this.avatar = response.data.data.avatar;
              this.addr = response.data.data.area;
              this.username = response.data.data.name;
              this.gender = response.data.data.gender;
          })
          .catch(function () {})
          .then(function () {
          });
      },

      logout() {
        axios.get(`/api/user/logout`)
          .then(() => {
              this.$store.dispatch("user", null);
              this.$snackbar.showMessage({ content: '成功退出' })
          })
          .catch(function () {})
          .then(function () {
          });
      }

    },
  };
</script>