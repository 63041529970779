<template>
    <v-card class="pa-4">
      <v-form ref="form">
        <v-row dense no-gutters>
          <v-col>
            <div class="subtitle-2">
              回复
            </div>
          </v-col>
          <v-spacer></v-spacer>
          <v-col>
              <v-icon class="float-right" small @click="close">mdi-close</v-icon>
          </v-col>
        </v-row>

        <v-textarea
          class="mt-2 body-2"
          placeholder="请输入"
          outlined
          flat
          dense
          no-resize
          hide-details
          color="grey"
          rows="3"
          v-model="textContent"
        ></v-textarea>

        <div class="mt-2 d-flex align-center">
          <emoji-picker @emoji="insertEmoji" class="emoji-container">
            <div slot="emoji-invoker" slot-scope="{ events: { click: clickEvent } }" @click.stop="clickEvent">
                <v-icon color="#58BCB3" style="cursor: pointer">mdi-emoticon-happy-outline</v-icon>
            </div>
            <div slot="emoji-picker" slot-scope="{ emojis, display }">
                <div class="emojis" :style="{ top: display.y + 'px', left: display.x + 'px' }">
                  <template v-for="(emojiGroup, category) in emojis">
                      <template v-for="(emoji, emojiName)  in emojiGroup">
                        <span
                          :key="category+emojiName"
                          :title="category+emojiName"
                          @click="insertEmoji(emoji)"
                        >{{ emoji }}</span>
                      </template>
                  </template>
                </div>
              </div>
          </emoji-picker>
        </div>
          

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            dark
            color="#7A7E7E"
            elevation="0"
            @click="close"
          >
            取消
          </v-btn>
          <v-btn
            dark
            color="#58BCB3"
            elevation="0"
            @click="saveData"
            :loading="saving"
          >
            发布
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
</template>

<script>
  import axios from "axios";
  export default {
    props: {
      href: {
        type: String,
        required: true
      }
    },
    data: () => ({
      textContent: '',
      saving: false
    }),

    computed: {
    },

    created: function () {
     
    },

    mounted () {
    },

    unmounted(){

    },

    methods: {
      insertEmoji(emoji) {
        this.textContent += emoji;
      },

      close() {
        this.textContent = '';
        this.$emit('closeEvent');
      },

       success() {
        this.textContent = '';
        this.$emit('successEvent');
      },

      saveData() {
        if (!this.$refs.form.validate()) {
          this.$snackbar.showMessage({ content: '输入字段有误' });
          return;
        }

        const that = this;
        that.saving = true;
        axios.post(this.href, {
            content: this.textContent,
          }
        ).then((response) => {
            if (response.data.code === 0) {
              this.$snackbar.showMessage({ content: '回复成功' });
              this.success()
            } else {
              this.$snackbar.showMessage({ content: response.data.msg })
            }
        })
        .catch(function () {})
        .then(function () {
            that.saving = false;
        });
      }
    },
  };
</script>

<style lang="scss" scoped>
</style>