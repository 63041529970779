import VueRouter from "vue-router";
import Vue from "vue";
import store from "./store";

import Index from "../components/index/Index";
import Login from "../components/login/Index";
import Register from "../components/login/Register";
import ForgetPassword from "../components/login/ForgetPassword";
import ResetPassword from "../components/login/ResetPassword";
import AddPost from "../components/index/AddPost";
import Post from "../components/index/Post";
import Announcement from "../components/index/Announcement";
import Author from "../components/index/Author";
import Search from "../components/index/Search";
import SearchPost from "../components/index/SearchPost";
import SearchUser from "../components/index/SearchUser";
import Columns from "../components/index/Columns";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        component: Index,
        name: "home",
        meta: {
            searchBar: true
        }
    },
    {
        path: "/columns",
        component: Columns,
        name: "columns",
    },
    {
        path: "/search",
        redirect: "/search/post",
        component: Search,
        name: "search",
        meta: {
            searchBar: true
        },
        children: [{
            path: 'post',
            component: SearchPost,
            meta: {
                searchBar: true
            },
        },
        {
            path: 'user',
            component: SearchUser,
            meta: {
                searchBar: true
            },
        }]
    },
    {
        path: "/post/add",
        component: AddPost,
        name: "post-add",
    },
    {
        path: "/post/detail/:id",
        component: Post,
        name: "post-detail",
        meta: {
            footer: true
        }
    },
    {
        path: "/announcement/detail/:id",
        component: Announcement,
        name: "announcement-detail",
        meta: {
            footer: true
        }
    },
    {
        path: "/author/detail/:id",
        component: Author,
        name: "author-detail",
    },
    {
        path: "/login",
        component: Login,
        name: "login",
        meta: {
            banner: false
        }
    },
    {
        path: "/register",
        component: Register,
        name: "register",
        meta: {
            banner: false
        }
    },
    {
        path: "/forget-password",
        component: ForgetPassword,
        name: "forget-password",
    },
    {
        path: "/reset-password",
        component: ResetPassword,
        name: "reset-password",
    }
];

const router = new VueRouter({
    routes,
    /* eslint-disable no-unused-vars */
    scrollBehavior(to, from, savedPosition) {
        // return 期望滚动到哪个的位置
        if (savedPosition) {
            return savedPosition;
        } else {
            return { x: 0, y: 0 };
        }
    }
});

/* eslint-disable no-unused-vars */
router.beforeEach((to, from, next) => {
    next();
});

/* eslint-disable no-unused-vars */
router.afterEach((to, from) => {
    if (to.meta && typeof to.meta.banner !== 'undefined') {
        store.commit("appBar", to.meta.banner);
    } else {
        store.commit("appBar", true);
    }

    if (to.meta && typeof to.meta.footer !== 'undefined') {
        store.commit("appFooter", to.meta.footer);
    } else {
        store.commit("appFooter", false);
    }

    if (to.meta && typeof to.meta.searchBar !== 'undefined') {
        store.commit("searchBar", to.meta.searchBar);
    } else {
        store.commit("searchBar", false);
    }
})

export default router;