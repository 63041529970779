<template>
  <v-container fluid class="pa-0 bg-img" 
    :style="{'background-image':  config.login_img.length > 0 ? 'url('+ config.login_img+ ')' : ''}">
      <v-row justify="center" class="mt-16">
        <v-col cols="auto" >
          <!-- <v-img
            src="../../assets/img/logo-w.png"
            width="160"
           >
          </v-img> -->
        </v-col>
      </v-row>
      
      <v-row justify="center" class="mt-16 pa-5">
        <v-card max-width="451" class="pa-md-7" flat>
           <v-img
            class="handcraft d-lg-flex d-xl-flex mr-15 mr-sm-0"
            src="../../assets/img/spaceship.png"
            width="146"
           >
          </v-img>
          <v-form ref="form" >
            <v-row justify="center"  class="mt-2">
              <v-col cols="auto" class="title">欢迎登录</v-col>
            </v-row>

            <v-row no-gutters class="mx-7 mt-6">
              <v-col cols="12" class="d-flex">
                <v-text-field
                  placeholder="请输入登录邮箱"
                  hide-details="auto"
                  dense
                  outlined
                  flat
                  type="email"
                  color="grey"
                  class="pa-0 ma-0 body-2"
                  v-model="email"
                  :rules="emailRules"
                  :ripple="false"
                  validate-on-blur
                >
                  <template v-slot:prepend-inner>
                      <v-img src="../../assets/icon/feather/mail.svg" width="16" height="16" class="mt-1"></v-img>
                  </template>
                </v-text-field>
              </v-col>

               <v-col cols="12" class="mt-6">
                <v-text-field
                  placeholder="请输入密码"
                  hide-details="auto"
                  dense
                  outlined
                  flat
                  type="password"
                  color="grey"
                  class="pa-0 ma-0 body-2"
                  v-model="password"
                  :rules="passwordRules"
                  :ripple="false"
                  validate-on-blur
                >
                  <template v-slot:prepend-inner>
                      <v-img src="../../assets/icon/feather/lock.svg" width="16" height="16" class="mt-1"></v-img>
                  </template>
                </v-text-field>
              </v-col>

              <v-col cols="12" class="mt-6 d-flex align-center justify-space-between">
                <div>
                  <v-checkbox  
                    hide-details
                    dense 
                    color="#58BCB3"
                    v-model="rememberMe" 
                    class="pa-0"
                    >
                    <template v-slot:label>
                      <div class="subtitle-2">记住我</div>
                    </template>
                  </v-checkbox>
                </div>
                <div>
                  <router-link class="primary-link" to="/forget-password">忘记密码</router-link>
                </div>
              </v-col>


              <v-col cols="12" class="mt-6">
                <v-btn
                    block
                    dark
                    color="#58BCB3"
                    elevation="0"
                    large
                    :loading="logining"
                    @click="doLogin()"
                  >登录
                  </v-btn>
              </v-col>

              <v-col cols="12" class="mt-6 mb-6">
                <v-btn
                    block
                    outlined
                    dark
                    color="#58BCB3"
                    elevation="0"
                    to="/register"
                    large
                  >注册账号
                  </v-btn>
              </v-col>
              </v-row>
          </v-form>
        </v-card>
      </v-row>
  </v-container>
</template>

<script>
  import axios from "axios";
  import { mapGetters } from 'vuex';

  export default {
    name: 'Login',

    data: () => ({
      rememberMe: true,
      email: '',
      password: '',
      logining: false,
    }),

    computed: {
      emailRules: function () {
        return [
          (v) => !!v || '必填',
          (v) => /.+@.+/.test(v) || '邮箱格式不正确',
        ];
      },
      passwordRules: function () {
        return [
          (v) => !!v || '必填',
        ];
      },  

       ...mapGetters([
        'config',
      ]),
    },

    methods: {
      doLogin() {
        if (!this.$refs.form.validate()) {
          this.$snackbar.showMessage({ content: '输入字段有误' });
          return;
        }

        const that = this;
        that.logining = true;
        axios.post("/api/user/login", {
            email: this.email,
            password: this.password,
            remember_me: this.rememberMe,
          }
        ).then((response) => {
            if (response.data.code === 0) {
              this.$store.dispatch("user", response.data.data);

              this.$snackbar.showMessage({ content: '登录成功' })
              this.$router.push({ path: '/' });
            } else {
              this.$snackbar.showMessage({ content: response.data.msg })
            }
        })
        .catch(function () {})
        .then(function () {
            that.logining = false;
        });
      }
  },
  }
</script>

<style lang="scss" scoped>

.handcraft {
  position: absolute;
  top: -72px;
  right: -47px;
}
</style>