const snackbarPlugin = {
    install: (Vue, { store }) => {
        if (!store) {
            throw new Error('Please provide vuex store.');
        }

        Vue.prototype.$snackbar = {
            showMessage: function ({
                content = '',
                color = '',
                timeout = ''
            }) {
                store.commit(
                    'showSnackbarMessage',
                    { content, color, timeout },
                    { root: true }
                );
            }
        };
    },
};

export default snackbarPlugin;