<template>
  <div>
    <v-form ref="form" >
      <v-row no-gutters class="mx-7 mt-6">
        <v-col cols="12" class="mt-6 readonly">
          <v-text-field
            placeholder="邮箱"
            hide-details
            dense
            outlined
            flat
            type="email"
            color="grey"
            class="pa-0 ma-0 body-2 "
            background-color="#F1F1F1"
            v-model="email"
            disabled
            readonly
            :ripple="false"
          >
            <template v-slot:prepend-inner>
                <v-img src="../../assets/icon/feather/mail.svg" width="16" height="16" class="mt-1"></v-img>
            </template>
          </v-text-field>
        </v-col>

        <v-col cols="12" class="mt-6">
          <v-text-field
            placeholder="请输入旧密码"
            hide-details
            dense
            outlined
            flat
            type="password"
            color="grey"
            class="pa-0 ma-0 body-2"
            v-model="oldPassword"
            :ripple="false"
          >
            <template v-slot:prepend-inner>
                <v-img src="../../assets/icon/feather/lock.svg" width="16" height="16" class="mt-1"></v-img>
            </template>
          </v-text-field>
        </v-col>

        <v-col cols="12" class="mt-6">
          <v-text-field
            placeholder="请输入密码"
            hide-details
            dense
            outlined
            flat
            type="password"
            color="grey"
            class="pa-0 ma-0 body-2"
            v-model="password"
            :ripple="false"
          >
            <template v-slot:prepend-inner>
                <v-img src="../../assets/icon/feather/lock.svg" width="16" height="16" class="mt-1"></v-img>
            </template>
          </v-text-field>
        </v-col>

        <v-col cols="12" class="mt-6">
          <v-text-field
            placeholder="请再次输入密码"
            hide-details
            dense
            outlined
            flat
            type="password"
            color="grey"
            class="pa-0 ma-0 body-2"
            v-model="passwordConfirm"
            :ripple="false"
          >
            <template v-slot:prepend-inner>
                <v-img src="../../assets/icon/feather/lock.svg" width="16" height="16" class="mt-1"></v-img>
            </template>
          </v-text-field>
        </v-col>
      </v-row>
      
      <v-card-actions class="mt-4">
        <v-spacer></v-spacer>
        <v-btn
          
          dark
          color="#7A7E7E"
          elevation="0"
          @click="close"
        >
          取消
        </v-btn>
        <v-btn
          
          dark
          color="#58BCB3"
          elevation="0"
          @click="saveData"
          :loading="saving"
        >
          提交
        </v-btn>
      </v-card-actions>
    </v-form>
  </div>
</template>

<script>
  import axios from "axios";
  import { mapGetters } from 'vuex';

  export default {
    data: () => ({
      email: '',
      oldPassword: '',
      password: '',
      passwordConfirm: '',
    }),
    
    computed: {
      ...mapGetters([
        'uid',
      ]),
    },

    created: function () {
      this.loadUser()
    },

    mounted () {
    },

    unmounted(){

    },

    methods: {
      close() {
        this.password = '';
        this.oldPassword = '';
        this.passwordConfirm = '';
        this.$emit('closeEvent');
      },

      loadUser() {
        axios.get(`/api/u/${this.uid}`)
          .then((response) => {
              this.email = response.data.data.email;
          })
          .catch(function () {})
          .then(function () {
        });
      },

      saveData() {
        if (!this.$refs.form.validate()) {
          this.$snackbar.showMessage({ content: '输入字段有误' });
          return;
        }

        const that = this;
        that.saving = true;
        axios.post("/api/user/password/edit", {
            old_password: this.oldPassword,
            password: this.password,
          }
        ).then((response) => {
            if (response.data.code === 0) {
              this.$snackbar.showMessage({ content: '修改成功' });
              // reset form value
              this.close()
            } else {
              this.$snackbar.showMessage({ content: response.data.msg })
            }
        })
        .catch(function () {})
        .then(function () {
            that.saving = false;
        });
      }
    },
  };
</script>

<style lang="scss" scoped>
.custom-tab {
  min-width: 0px;
}

.avatar-tip {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  

  .text-tip {
    color: white;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>