<template>
  <v-container class="pa-0">
      <masonry
        :gutter="{ default: '16px', 700: '8px' }"
        :cols="{ default: 4, 1000: 3, 700: 2, 500: 1 }"
      >
        <!-- 公告 -->
        <v-card class="mt-4 mb-4 shadow-card billboard" v-if="announcement" :style="{'background-color': announcement.color}">
          <div class="pa-4">
            <div class="mt-2 line-2" v-if="announcement.title.length > 0">
              <router-link :to="`/announcement/detail/${announcement.id}`" 
                class="text-decoration-none subtitle-1 font-weight-bold white--text text--darken-3">{{announcement.title}}
              </router-link>
            </div>

            <div class="body-2 mt-2" 
              v-if="announcement.content.length > 0">
              <router-link :to="`/announcement/detail/${announcement.id}`" 
                class="text-decoration-none white--text line-3"
              > 
                {{announcement.content}} 
              </router-link>
            </div>
            <div class="mt-2">
              <v-btn
                  dark
                  small
                  color="#58BCB3"
                  elevation="0"
                  :to="`/announcement/detail/${announcement.id}`" 
                >查看公告
                 <v-icon size="12" class="ml-2 mt-1">mdi-arrow-right</v-icon>
                </v-btn>
            </div>

            <v-row dense align="center" class="mt-2 text-caption white--text">
              <v-col cols="auto">
                {{announcement.date.slice(0, 10)}}
              </v-col>
               <v-col  cols="auto" class="ml-2 d-inline-flex align-center">
                 <v-img class="icon" src="../../assets/handpainted/dianzan_bai.png" width="12" height="12" contain></v-img>
                 <div class="ml-1">{{announcement.like_count}}</div>
              </v-col>
               <v-col  cols="auto" class="ml-2 d-inline-flex align-center">
                 <v-img class="icon"  src="../../assets/handpainted/pinglun_bai.png" width="12" height="12" contain></v-img>
                 <div class="ml-1">{{announcement.comment_count}}</div>
              </v-col>
            </v-row>
          </div>
        </v-card>

        <!-- 专栏 -->
        <v-card class="mt-4 mb-4 shadow-card column-post"  v-if="columnPost">
          
          <template v-if="columnPost.img.length > 0">
            
            <router-link :to="`/post/detail/${columnPost.id}`" >
              <v-img class="rounded-t" :src="columnPost.img"></v-img>
            </router-link>
          
          </template>

          <template v-else-if="columnPost.imgs && columnPost.imgs.length == 1">
            
            <router-link :to="`/post/detail/${columnPost.id}`" >
              <v-img class="rounded-t" :src="columnPost.imgs[0]"></v-img>
            </router-link>
          
          </template>

          <template v-else-if="columnPost.imgs && columnPost.imgs.length > 1">
            <div class="px-2 pt-2">
              <v-row  dense align="center">
                <v-col cols="4" v-for="(pic, j) in columnPost.imgs" :key="j" :class="{'pt-0': j < 3}">
                  <router-link :to="`/post/detail/${columnPost.id}`" >
                  <v-img class="rounded-sm" :src="pic" aspect-ratio="1"></v-img>
                </router-link>
                </v-col>
              </v-row>
            </div>
          </template>

          <div class="pa-4">
            <v-row v-if="!$route.path.includes('/author')" dense align="center">
              <router-link class="primary-link ml-2" :to="`/author/detail/${columnPost.user.id}`">
                <v-avatar size="28" >
                  <v-img v-if="columnPost.user.avatar" :src="columnPost.user.avatar" ></v-img> 
                  <v-img v-else src="../../assets/icon/feather/user.svg"  width="16" height="16" contain></v-img>
                </v-avatar>
              </router-link>
              <router-link class="primary-link ml-2" :to="`/author/detail/${columnPost.user.id}`">{{columnPost.user.name}}</router-link>
            </v-row>

            <div class="my-5">
              <div class=" mt-2 line-2" v-if="columnPost.title.length > 0">
                <router-link :to="`/post/detail/${columnPost.id}`" 
                  class="text-decoration-none subtitle-1 font-weight-bold grey--text text--darken-3">{{columnPost.title}}
                </router-link>
              </div>

              <div class="body-2 mt-2 line-5" 
                v-if="columnPost.content.length > 0">
                <router-link :to="`/post/detail/${columnPost.id}`" 
                  class="text-decoration-none text--primary"
                  :class="{'line-3': columnPost.imgs && columnPost.imgs.length > 0}" 
                > 
                  {{columnPost.content}} 
                </router-link>
              </div>
            </div>

            <v-row dense align="center" class="mt-2 text-caption text--disabled" >
              <v-col cols="auto">
                {{columnPost.create_time.slice(0, 10)}}
              </v-col>
               <v-col  cols="auto" class="ml-2 d-inline-flex align-center">
                 <v-img class="icon" src="../../assets/handpainted/dianzan_hui.png" width="12" height="12" contain></v-img>
                 <div class="ml-1">{{columnPost.like_count}}</div>
              </v-col>
               <v-col  cols="auto" class="ml-2 d-inline-flex align-center">
                 <v-img class="icon"  src="../../assets/handpainted/pinglun_hui.png" width="12" height="12" contain></v-img>
                 <div class="ml-1">{{columnPost.comment_count}}</div>
              </v-col>
            </v-row>

            <div class="mt-2">
              <v-btn
                  dark
                  small
                  color="#58BCB3"
                  elevation="0"
                  :to="`/columns`" 
                >更多专栏内容
                 <v-icon size="12" class="ml-2 mt-1">mdi-arrow-right</v-icon>
                </v-btn>
            </div>
          </div>
        </v-card>


        <!-- 帖子 -->
        <v-card v-for="(item, i) in posts" :key="i" class="mt-4 mb-4 shadow-card" >
          
          <template v-if="item.img.length > 0">
            
            <router-link :to="`/post/detail/${item.id}`" >
              <v-img class="rounded-t" :src="item.img"></v-img>
            </router-link>
          
          </template>

          <template v-else-if="item.imgs && item.imgs.length == 1">
            
            <router-link :to="`/post/detail/${item.id}`" >
              <v-img class="rounded-t" :src="item.imgs[0]"></v-img>
            </router-link>
          
          </template>

          <template v-else-if="item.imgs &&  item.imgs.length > 1">
            <div class="px-2 pt-2">
              <v-row  dense align="center">
                <v-col cols="4" v-for="(pic, j) in item.imgs" :key="j" :class="{'pt-0': j < 3}">
                  <router-link :to="`/post/detail/${item.id}`" >
                  <v-img class="rounded-sm" :src="pic" aspect-ratio="1"></v-img>
                </router-link>
                </v-col>
              </v-row>
            </div>
          </template>

          <div class="pa-4">
            <v-row v-if="!$route.path.includes('/author') && item.user" dense align="center">
              <router-link class="primary-link ml-2" :to="`/author/detail/${item.user.id}`">
                <v-avatar size="28" >
                  <v-img v-if="item.user.avatar" :src="item.user.avatar" ></v-img> 
                  <v-img v-else src="../../assets/icon/feather/user.svg"  width="16" height="16" contain></v-img>
                </v-avatar>
              </router-link>
              <router-link class="primary-link ml-2" :to="`/author/detail/${item.user.id}`">{{item.user.name}}</router-link>
            </v-row>
            <div class="my-5">
            <div class=" mt-2 line-2" v-if="item.title.length > 0">
              <router-link :to="`/post/detail/${item.id}`" 
                class="text-decoration-none subtitle-1 font-weight-bold grey--text text--darken-3">{{item.title}}
              </router-link>
            </div>

            <div class="body-2 mt-2 line-5" 
              v-if="item.content.length > 0">
              <router-link :to="`/post/detail/${item.id}`" 
                class="text-decoration-none text--primary"
                :class="{'line-3': item.imgs && item.imgs.length > 0}" 
              > 
                {{item.content}} 
              </router-link>
            </div>
          </div>

            <v-row dense align="center" class="mt-2 text-caption text--disabled" >
              <v-col cols="auto">
                {{item.create_time.slice(0, 10)}}
              </v-col>
               <v-col  cols="auto" class="ml-2 d-inline-flex align-center">
                 <v-img class="icon" src="../../assets/handpainted/dianzan_hui.png" width="12" height="12" contain></v-img>
                 <div class="ml-1">{{item.like_count}}</div>
              </v-col>
               <v-col  cols="auto" class="ml-2 d-inline-flex align-center">
                 <v-img class="icon"  src="../../assets/handpainted/pinglun_hui.png" width="12" height="12" contain></v-img>
                 <div class="ml-1">{{item.comment_count}}</div>
              </v-col>
            </v-row>
          </div>
        </v-card>
      </masonry>

    <v-row no-gutters v-intersect="loadData" align="center" justify="center" v-if="!finished && !loading && page_no > 1" class="mt-4">
      <v-progress-circular size="12" width="2" indeterminate color="#9A9A9A"></v-progress-circular>
      <p class="caption text-center mt-4">正在加载更多...</p>
    </v-row>

    <div v-if="finished && posts.length == 0" class="mt-2">
      <p class="text--disabled text-center text-caption">暂无相关内容</p>
    </div>

  </v-container>
</template>

<script>
  import axios from "axios";

  export default {
    props: {
      href: {
        type: String,
        required: true
      },
      announcement: { //公告
        type: Object,
        default: null
      },
      columnPost: { //专栏
        type: Object,
        default: null
      }
    },

    data: () => ({
      posts: [],
      page_no: 1,
      page_size: 10,
      loading: false,
      finished: false,
    }),

    mounted: function () {
      this.page_no = 1;
      this.finished = false;
      this.posts = [];
      this.loadData();
    },

    methods: {
      loadData () {
        const that = this;
        that.loading = true;

        axios.get(that.href, {
            params: {
              page_no: that.page_no,
              page_size: that.page_size,
            },
          })
          .then((response) => {
              that.posts.push.apply(that.posts, response.data.data.items);
              // 加载完成
              if (response.data.data.items.length < that.page_size) {
                that.finished = true;
              }
          })
          .catch(function () {})
          .then(function () {
             that.loading = false;
             that.page_no += 1;
          });
      },
    },
  };
</script>

<style lang="scss" scoped>
.row--dense > .col, .row--dense > [class*=col-] {
  padding: 2px;
}


.theme--light.v-card.billboard {
  background-color: #7194DB;
  color: white;
}

.column-post {
  border:  4px solid rgba(241, 196, 15, 1);
}
</style>