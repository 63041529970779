import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

const store = new Vuex.Store({
    state: {
        version: "1.0",
        user: {
            token: null, //身份认证token
            uid: 0,
            name: '',
            avatar: '',
            is_star: 0, //专栏作者
            is_super: 0 //管理员
        },
        config: {
            banner_img: '',
            login_img: '',
            notice_color: '',
        },
        appBar: false,
        searchBar: false,
        appFooter: false,
        searchKeyword: '',
        searchType: 'post',
        snackbar: {
            content: '',
            color: '',
            timeout: ''
        },
    },
    mutations: {
        user: function (state, user) {
            state.user = user;
            if (user === null) {
                state.user = {
                    token: null,
                    uid: 0,
                    name: '',
                    avatar: '',
                };

                localStorage.removeItem("user");
            } else {
                user.avatar = user.avatar === null ? '' : user.avatar;
                localStorage.setItem("user", JSON.stringify(user));
            }
        },
        showSnackbarMessage(state, payload) {
            state.snackbar.content = payload.content
            state.snackbar.color = payload.color
            state.snackbar.timeout = payload.timeout
        },
        appBar(state, show) {
            state.appBar = show;
        },
        appFooter(state, show) {
            state.appFooter = show;
        },
        searchBar(state, show) {
            state.searchBar = show;
        },
        searchKeyword(state, k) {
            state.searchKeyword = k ? k : '';
        },
        searchType(state, type) {
            state.searchType = type == 'user' ? 'user' : 'post';
        },
        username(state, username) {
            state.username = username;
        },
        config(state, config) {
            state.config = config;
        }
    },
    actions: {
        user(context, user) {
            context.commit("user", user);
        },
    },
    getters: {
        uid: state => {
            let uid = 0;
            // 如果没有数据则检查本地存储
            if (state.user.uid) {
                uid = state.user.uid;
            } else {
                let obj = JSON.parse(localStorage.getItem("user"));
                if (obj === null) {
                    return 0;
                } else {
                    state.user = obj;
                    uid = state.user.uid;
                }
            }
            return isNaN(parseInt(uid)) ? 0 : parseInt(uid);
        },
        user: state => {
            // 如果没有数据则检查本地存储
            if (state.user.uid) {
                return state.user;
            } else {
                let obj = JSON.parse(localStorage.getItem("user"));
                if (obj === null) {
                    return state.user;
                } else {
                    state.user = obj;
                    return state.user;
                }
            }
        },
        username: state => state.username,
        avatar: state => state.avatar,
        appBar: state => state.appBar,
        appFooter: state => state.appFooter,
        searchBar: state => state.searchBar,
        searchKeyword: state => state.searchKeyword,
        searchType: state => state.searchType,
        config: state => state.config,
    }
});
export default store;