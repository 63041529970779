<template>
  <v-container>
    <v-row justify="center" no-gutters>
      <v-col cols="12"  >
        <v-img v-if="config.banner_img.length > 0" :src="config.banner_img" ></v-img>
        <v-img v-else src="../../assets/img/banner.png" ></v-img>
      </v-col>
    </v-row>
    
    <post-water-fall href="/api/post/star/feed" :announcement="announcement" :columnPost="columnPost"></post-water-fall>

    <fab-btn add></fab-btn>

  </v-container>
</template>

<script>
  import FabBtn from "../widgets/FabBtn.vue";
  import PostWaterFall from "../widgets/PostWaterFall.vue";
  import { mapGetters } from 'vuex';

  export default {
    name: 'Home',
    data: () => ({
      announcement: null,
      columnPost: null
    }),

    components: {
      FabBtn,
      PostWaterFall
    },

    computed: {
      ...mapGetters([
        'config',
      ]),
    },

  };
</script>