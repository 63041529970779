<template>
  <v-container fluid class="pa-0 bg-img"  
    :style="{'background-image':  config.login_img.length > 0 ? 'url('+ config.login_img+ ')' : ''}">
      <v-row justify="center" class="mt-16">
        <v-col cols="auto" >
          <!-- <v-img
            src="../../assets/img/logo-w.png"
            width="160"
           >
          </v-img> -->
        </v-col>
      </v-row>
      
      <v-row justify="center" class="mt-4 mb-16 pa-5">
        <v-card max-width="451" class="pa-md-7" flat>
           <v-img
            class="handcraft d-lg-flex d-xl-flex mr-15 mr-sm-0"
            src="../../assets/img/xiaoren.png"
            width="146"
           >
          </v-img>
          <v-form ref="form" >
            <v-row justify="center"  class="mt-2">
              <v-col cols="auto" class="title">新建账号</v-col>
            </v-row>

            <v-row no-gutters class="mx-7 mt-6">
              <v-col cols="12" class="d-flex align-center justify-center flex-column">
                  <v-file-input
                    style="display: none;"
                    ref="file"
                    hide-input
                    accept="image/*"
                    @change="changeFile"
                  >
                  </v-file-input>
                  <div 
                    @click="$refs.file.$refs.input.click()" v-ripple
                    class="avatar d-flex align-center justify-center flex-column">
                      <template v-if="avatar.length > 0" >
                          <v-img class="flex-grow-0 icon circle"  :src="avatar" width="100" height="100">
                          </v-img>
                      </template>
                       <template v-else>
                          <v-img class="flex-grow-0 icon " src="../../assets/icon/feather/camera.svg" width="24" height="24" contain>
                          </v-img>
                          <div style="font-size: 12px;color:#707070;">上传头像</div>
                      </template>
                  </div>
                  <div style="font-size: 12px;color:#707070; margin-top:6px;">建议尺寸：100*100</div>
              </v-col>

              <v-col cols="12" class="mt-6">
                <v-text-field
                  placeholder="请设置您的昵称"
                  hide-details="auto"
                  validate-on-blur
                  :rules="usernameRules"
                  dense
                  outlined
                  flat
                  v-model="username"
                  color="grey"
                  class="pa-0 ma-0 body-2"
                  :ripple="false"
                >
                  <template v-slot:prepend-inner>
                      <v-img src="../../assets/icon/feather/user-grey.svg" width="16" height="16" class="mt-1"></v-img>
                  </template>
                </v-text-field>
              </v-col>

              <v-col cols="12" class="mt-6 d-flex align-center ">
                <v-radio-group
                  v-model="gender"
                  hide-details="auto"
                  dense
                  row
                  class="pa-0 ma-0"
                >
                  <v-radio
                    value="1"
                    color="#58BCB3"
                  >
                    <template v-slot:label>
                      <div class="subtitle-2">男</div>
                    </template>
                  </v-radio>
                  <v-radio
                    value="2"
                    color="#58BCB3"
                  >
                    <template v-slot:label>
                      <div class="subtitle-2">女</div>
                    </template>
                  </v-radio>
                </v-radio-group>
              </v-col>

              <v-col cols="12" class="mt-6">
                <v-menu
                  ref="menu"
                  v-model="datepickerMenu"
                  :close-on-content-click="false"
                  :return-value.sync="birthday"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                     <v-text-field
                      placeholder="请选择您的生日"
                      hide-details="auto"
                      dense
                      outlined
                      flat
                      color="grey"
                      class="pa-0 ma-0 body-2"
                      v-model="birthday"
                      :ripple="false"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    >
                      <template v-slot:prepend-inner>
                        <v-img src="../../assets/icon/feather/calendar.svg" width="16" height="16" class="mt-1"></v-img>
                      </template>
                      <template v-slot:append>
                        <v-img src="../../assets/icon/feather/chevron-down.svg" width="16" height="16" class="mt-1"></v-img>
                      </template>
                    </v-text-field>
                  </template>
                  <v-date-picker
                    v-model="birthday"
                    no-title
                    scrollable
                    :first-day-of-week="0"
                    locale="zh-cn"
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      color="primary"
                      @click="menu = false"
                    >
                      取消
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.menu.save(birthday)"
                    >
                      确定
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>

              <v-col cols="12" class="mt-6">
                <v-text-field
                  placeholder="请输入您所在地区"
                  dense
                  outlined
                  flat
                  color="grey"
                  class="pa-0 ma-0 body-2"
                  v-model="addr"
                  :ripple="false"
                  hide-details="auto"
                >
                  <template v-slot:prepend-inner>
                    <v-img src="../../assets/icon/feather/map-pin.svg" width="16" height="16" class="mt-1"></v-img>
                  </template>
                </v-text-field>
              </v-col>

              <v-col cols="12" class="mt-6">
                <v-text-field
                  placeholder="请输入邮箱用于登录和密码找回"
                  dense
                  outlined
                  flat
                  type="email"
                  color="grey"
                  class="pa-0 ma-0 body-2"
                  v-model="email"
                  hide-details="auto"
                  validate-on-blur
                  :rules="emailRules"
                  :ripple="false"
                >
                  <template v-slot:prepend-inner>
                    <v-img src="../../assets/icon/feather/mail.svg" width="16" height="16" class="mt-1"></v-img>
                  </template>
                </v-text-field>
              </v-col>

              <v-col cols="12" class="mt-6">
                <v-text-field
                  placeholder="请输入密码"
                  dense
                  outlined
                  flat
                  type="password"
                  color="grey"
                  class="pa-0 ma-0 body-2"
                  v-model="password"
                  hide-details="auto"
                  validate-on-blur
                  :rules="passwordRules"
                  :ripple="false"

                >
                 <template v-slot:prepend-inner>
                    <v-img src="../../assets/icon/feather/lock.svg" width="16" height="16" class="mt-1"></v-img>
                  </template>
                </v-text-field>
              </v-col>

              <v-col cols="12" class="mt-6">
                <v-text-field
                  placeholder="请再次输入密码"
                  hide-details="auto"
                  dense
                  outlined
                  flat
                  type="password"
                  color="grey"
                  class="pa-0 ma-0 body-2"
                  v-model="passwordConfirm"
                  :ripple="false"
                  :rules="passwordRules"
                >
                  <template v-slot:prepend-inner>
                    <v-img src="../../assets/icon/feather/lock.svg" width="16" height="16" class="mt-1"></v-img>
                  </template>
                </v-text-field>
              </v-col>


              <v-col cols="12" class="mt-6 pb-6">
                <v-btn
                    block
                    dark
                    color="#58BCB3"
                    elevation="0"
                    large
                    :loading="registing"
                    @click="doRegister"
                  >注册
                  </v-btn>
                  <div class="mt-6 caption d-flex align-center justify-center">
                    <a href="#/login" class="text-decoration-none ">已有账号？去登录</a></div>
              </v-col>
              </v-row>
          </v-form>
        </v-card>
      </v-row>
  </v-container>
</template>

<script>
  import axios from "axios";
  import { mapGetters } from 'vuex';

  export default {
    name: 'Register',
    data: () => ({
      datepickerMenu: false,
      username: '',
      email: '',
      birthday: '',
      addr: '',
      password: '',
      passwordConfirm: '',
      gender: 0,
      avatar: '',
      registing: false,
    }),

    computed: {
      emailRules: function () {
          return [
            (v) => !!v || '必填',
            (v) => /.+@.+/.test(v) || '邮箱格式不正确',
          ];
      },
      passwordRules: function () {
          return [
            (v) => !!v || '必填',
            (v) => v.length >= 6 || '密码至少6位',
          ];
      }, 
      usernameRules: function () {
          return [
            (v) => !!v || '必填',
          ];
      }, 
      ...mapGetters([
        'config',
      ]),
    },


    methods: {

      changeFile(file) {
        if (Array.isArray(file)) {
          file.forEach((v)=>{
              this.uploadFile(v);
          }, this)
        } else {
            this.uploadFile(file);
        }
      },

      uploadFile(file){
        let form= new FormData();
        form.append('file', file)
        this.uploading = true;
        const that = this;
        axios.post('/api/upload/avatar', form, { headers: {'Content-Type': 'multipart/form-data' } } )
        .then((response) => {
            if (response.data.code === 0) {
                this.avatar = response.data.data.url;
            } else {
                this.$snackbar.showMessage({ content: response.data.msg });
            }
        })
        .catch(function () {})
        .then(function () {
            that.uploading = false;
        });
      },
        
      doRegister() {
        if (!this.$refs.form.validate()) {
          this.$snackbar.showMessage({ content: '输入字段有误' });
          return;
        }

        const that = this;
        that.registing = true;
        axios.post("/api/user/register", {
            name: this.username,
            gender: this.gender,
            birthday: this.birthday,
            area: this.addr,
            avatar: this.avatar,
            email: this.email,
            password: this.password,
            password_confirmation: this.passwordConfirm,
        }).then((response) => {

            if (response.data.code === 0) {
              this.$store.dispatch("user", response.data.data);

              this.$snackbar.showMessage({ content: '注册成功' })
              this.$router.push({ path: '/' });
            } else {
              this.$snackbar.showMessage({ content: response.data.msg })
            }
        })
        .catch(function () {})
        .then(function () {
            that.registing = false;
        });
      },
    }
  }
</script>

<style lang="scss" scoped>

.handcraft {
  position: absolute;
  top: -92px;
  right: -67px;
}

.avatar {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #EFECEC;

  .circle {
    border-radius: 50%;
  }
}
</style>