<template>
    <v-container ref="container"> 
        <template v-if="uid">  
            <v-speed-dial
                v-if="showAdd"
                v-model="addFab"
                bottom
                right
                fixed
                direction="top"
                transition="slide-y-reverse-transition"
                v-bind:style="{right: right+'px', bottom: addFabBottom + 'px'}"
                >
                <template v-slot:activator>
                    <v-btn
                        fab
                        dark
                        color="#58BCB3"
                        class="add-btn"
                    >
                    <v-icon v-if="addFab">
                        mdi-close
                    </v-icon>
                    <v-icon v-else>
                        mdi-plus
                    </v-icon>
                    </v-btn>
                </template>
                <v-btn
                    fab
                    dark
                    color="#58BCB3"
                    to="/post/add"
                >
                    <v-img src="../../assets/icon/feather/file-text.svg" width="18" height="18" contain></v-img>
                </v-btn>
                <v-btn
                    fab
                    dark
                    color="#58BCB3"
                    @click="imageDialog = true"
                >
                <v-img src="../../assets/icon/feather/image.svg" width="18" height="18" contain></v-img>
                </v-btn>
                <v-btn
                    fab
                    color="#58BCB3"
                    @click="textDialog = true"
                >
                    <v-img src="../../assets/icon/feather/type.svg" width="18" height="18" contain></v-img>
                </v-btn>
            </v-speed-dial>
        </template>
        <template v-else>
            <v-btn
                fab
                dark
                color="#58BCB3"
                class="add-btn"
                to="/login"
                fixed
                v-bind:style="{right: right+'px', bottom: addFabBottom + 'px'}"
            >
            <v-icon>
                mdi-plus
            </v-icon>
            </v-btn>
        </template>

        <v-btn
            v-if="showHome"
            fab
            icon
            fixed
            elevation="6"
            color="#58BCB3"
            ref="gohome"
            to="/"
            style="background-color: white"
            v-bind:style="{right: right+'px', bottom: homeFabBottom + 'px'}"
        >
            <v-icon >mdi-home</v-icon>
        </v-btn>

        <v-btn
            v-if="showGotop"
            fab
            icon
            fixed
            elevation="6"
            color="#58BCB3"
            class="go-top"
            @click="$vuetify.goTo(0)"
            ref="gotop"
            style="background-color: white"
            v-bind:style="{right: right+'px', bottom: gutter+'px'}"
        >
        <v-icon >mdi-arrow-up</v-icon>
        </v-btn>

         <v-dialog
            v-model="textDialog"
            persistent
            max-width="600"
            >
            <text-form @closeEvent="textDialog = false"></text-form>
        </v-dialog>

        <v-dialog
            v-model="imageDialog"
            persistent
            max-width="600"
            >
            <image-form @closeEvent="imageDialog = false"></image-form>
        </v-dialog>
    </v-container>
</template>

<script>
import debounce from "debounce";
import TextForm from "./TextForm.vue";
import ImageForm from "./ImageForm.vue";
import { mapGetters } from 'vuex';

export default {

    props: {
      gotop: {
        type: Boolean,
        default: true
      },

      home: {
        type: Boolean,
        default: false
      },

      add: {
        type: Boolean,
        default: false
      },
    },

    components: {
        TextForm,
        ImageForm
    },

    data() {
        return {
            addFab: false,
            gutter: 16,
            right: 0,
            gotopHeight: 0,
            gohomeHeight: 0,
            textDialog: false,
            imageDialog: false,
            
            showHome: true,
            showGotop: true,
            showAdd: true,

        }
    },

    computed: {
        ...mapGetters([
            'uid',
        ]),

        homeFabBottom() {
            // 1 有goto, 
            // 2 无goto
            return this.showGotop ? this.gotopHeight + 12 + this.gutter : this.gutter;
        },

        addFabBottom() {
            // 1 有goto 有home
            // 2 有goto 无home
            // 3 无goto 有home
            // 4 无goto 无home
            if (this.showGotop && this.showHome) {
                // 2 个高度
                return  this.gotopHeight + 12 + this.gohomeHeight + 12 + this.gutter;
            } else if (!this.showGotop && !this.showHome) {
                // 0 个高度
                return this.gutter;
            } else {
                // 1 个高度
               return  this.showGotop ? this.gotopHeight + 12 + this.gutter : this.gohomeHeight + 12 + this.gutter;
            }
        }
    },

    mounted () {
        this.gotopHeight = this.$refs.gotop.$el.clientHeight;
        this.gohomeHeight = this.$refs.gohome.$el.clientHeight;

        // 第一个屏不展示回到顶部按钮
        this.showGotop = false;
        this.showAdd = this.add;
        this.showHome = this.home;

        let _this = this;//赋值vue的this
        _this.updateFabPosition();

        window.onresize = () => {
        _this.updateFabPosition();
        _this.updateGoTopFab()
        }

        window.onscroll = debounce(() => _this.updateGoTopFab(), 10);
    },

    unmounted(){
        window.onresize = null;
    },

    methods: {
        updateGoTopFab() {
            const sh = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
            const wh = window.innerHeight;
            if (sh > wh) {
                this.showGotop = true;
            } else {
                this.showGotop = false;
            }
        },

        updateFabPosition() {
            if (this.$refs.container && (window.innerWidth > this.$refs.container.clientWidth)) {
                const w = window.innerWidth - this.$refs.container.clientWidth;
                this.right = w / 2 + this.gutter * 2;
            } else {
                this.right = this.gutter;
            }
        },
    },
 
}
</script>