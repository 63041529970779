<template>
    <v-card class="pa-4">
      <v-row dense no-gutters>
        <v-col>
            <v-tabs  v-model="tab" height="28" color="#58BCB3">
              <v-tab class="pa-0 custom-tab font-weight-bold ">编辑资料</v-tab>
              <v-tab class="pa-0 custom-tab ml-2 font-weight-bold ">修改密码</v-tab>
            </v-tabs>
        </v-col>
        <v-col cols="auto">
            <v-icon small @click="close" class="mt-n4">mdi-close</v-icon>
        </v-col>
      </v-row>

     <v-tabs-items v-model="tab">
        <v-tab-item>
          <profile-form @closeEvent="close"></profile-form>
        </v-tab-item>
        <v-tab-item>
          <password-form  @closeEvent="close"></password-form>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
</template>

<script>
  import ProfileForm from "./ProfileForm.vue";
  import PasswordForm from "./PasswordForm.vue";

  export default {
    data: () => ({
      tab: 0,
    }),

    components: {
      ProfileForm,
      PasswordForm
    },

    created() {
     
    },

    mounted () {
    },

    methods: {
      close() {
        this.$emit('closeEvent');
      }
    },
  };
</script>

<style lang="scss" scoped>
.custom-tab {
  min-width: 0px;
}

.avatar-tip {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  

  .text-tip {
    color: white;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>