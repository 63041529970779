import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import axios from "axios";
import router from "./plugins/router";
import store from "./plugins/store";
import "./scss/app.scss"
import VueMasonry from 'vue-masonry-css'
import { EmojiPickerPlugin } from 'vue-emoji-picker'
import Photoswipe from "vue-pswipe"
import snackbar from "./plugins/snackbar";
import qs from 'qs';

Vue.use(snackbar, { store });

Vue.config.productionTip = false;

axios.defaults.withCredentials = false;
axios.defaults.baseURL = process.env.VUE_APP_HTTP_SERVER;

// Add a request interceptor
axios.interceptors.request.use(function (config) {
  if (config.method === 'post' && config.headers['Content-Type'] != 'multipart/form-data') {
    config.headers['Content-Type'] = "application/x-www-form-urlencoded"
    config.data = qs.stringify(config.data)
  }

  const user = JSON.parse(localStorage.getItem("user"));
  if (user === null) {
    //config.headers["Authorization"] = null;
    delete (config.headers["Authorization"]);
  } else {
    config.headers["Authorization"] = "Bearer " + user.token;
  }

  return config;
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});

axios.interceptors.response.use(function (response) {

  if (response.data.code === 9) {
    store.commit("user", null);
    router.push({
      path: '/login'
    }).catch(() => { });

  } else {
    return response;
  }
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});


Vue.use(VueMasonry);

Vue.use(EmojiPickerPlugin);

Vue.use(Photoswipe);

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
