<template>
  <v-container>
    <div v-if="searchKeyword.length == 0" class="mt-2">
      <p class="text--disabled text-center text-caption">关键词为空</p>
    </div>
    <post-water-fall v-else :href="`/api/search?kw=${searchKeyword}&type=2`"></post-water-fall>
  </v-container>
</template>

<script>
  import PostWaterFall from "../widgets/PostWaterFall.vue";
  import { mapGetters } from 'vuex';

  export default {
    data: () => ({
      k: ''
    }),

    computed: {
      ...mapGetters([
        'searchKeyword'
      ])
    },

    components: {
      PostWaterFall
    },

    created() {
    },

    mounted() {
      this.$store.commit("searchType", 'post'); 
    },

    methods: {
    
    },
  };
</script>