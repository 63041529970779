<template>
  <v-container fluid class="pa-0">
      <v-row justify="center" class="mt-4">
        <v-card max-width="600" class="px-16 shadow-card">
          <v-form ref="form" >
            <v-row no-gutters class="mx-7 mt-16">
              <v-col cols="12">
                <v-text-field
                  placeholder="请输入登录邮箱"
                  dense
                  outlined
                  flat
                  type="email"
                  color="grey"
                  class="pa-0 ma-0 body-2 email"
                  v-model="email"
                  hide-details="auto"
                  validate-on-blur
                  :rules="emailRules"
                  :ripple="false"
                  full-width
                >
                  <template v-slot:prepend-inner>
                      <v-img src="../../assets/icon/feather/mail.svg" width="16" height="16" class="mt-1"></v-img>
                  </template>
                </v-text-field>
              </v-col>

              <v-col cols="12" class="mt-6 d-flex justify-end">
                 <v-btn
                    dark
                    color="#58BCB3"
                    elevation="0"
                    @click="sendemail"
                  >提交

                  </v-btn>
                  <!-- <v-btn
                    color="#58BCB3"
                    elevation="0"
                    class="ml-4"
                    disabled
                  >重新发送
                  </v-btn> -->
              </v-col>

              <v-col cols="12" class="mt-6 text-center text--disabled">
               系统将发送带有重置密码的邮件至您的邮箱，请注意查收
              </v-col>
            </v-row>

             <v-row justify="center" class="mt-8 mb-10">
                <v-col cols="auto" >
                    <v-img src="../../assets/img/she.png" width="224" >
                    </v-img>
                </v-col>
            </v-row>
          </v-form>
        </v-card>
      </v-row>
  </v-container>
</template>

<script>
  import axios from "axios";
  export default {
    name: 'ForgetPassword',

    data: () => ({
      email: '',
    }),

    computed: {
      emailRules: function () {
          return [
            (v) => !!v || '必填',
            (v) => /.+@.+/.test(v) || '邮箱格式不正确',
          ];
      },
    },

    methods: {
      sendemail() {

        if (!this.$refs.form.validate()) {
          this.$snackbar.showMessage({ content: '邮箱输入有误' });
          return;
        }

        axios.post(`/api/user/sendemail`, {email: this.email})
        .then(response => {
          if (response.data.code === 0) {
              this.$snackbar.showMessage({ content: '邮件发送成功，请查收' })
          } else {
            this.$snackbar.showMessage({ content: response.data.msg })
          }
        });
      }
    }
  }
</script>

<style lang="scss" scoped>
.email {
  width: 400px;
}
</style>