<template>
  <v-container fluid class="pa-0">
      <v-row justify="center" class="mt-4">
        <v-card max-width="600" class="px-16 shadow-card">
          <v-form ref="form" >
            <v-row no-gutters class="mx-7 mt-1">

              <v-col cols="12" class="mt-6 text-center text--disabled body-2">
               请重新设置您的密码
              </v-col>

              <v-col cols="12" class="mt-6 readonly">
                <v-text-field
                  placeholder="邮箱"
                  hide-details
                  dense
                  outlined
                  flat
                  type="email"
                  color="grey"
                  class="pa-0 ma-0 body-2 "
                  background-color="#F1F1F1"
                  v-model="email"
                  disabled
                  readonly
                  :ripple="false"
                >
                 <template v-slot:prepend-inner>
                      <v-img src="../../assets/icon/feather/mail.svg" width="16" height="16" class="mt-1"></v-img>
                  </template>
                </v-text-field>
              </v-col>

              <v-col cols="12" class="mt-6">
                <v-text-field
                  placeholder="请输入密码"
                  hide-details
                  dense
                  outlined
                  flat
                  type="password"
                  color="grey"
                  class="pa-0 ma-0 body-2"
                  v-model="password"
                  :ripple="false"
                >
                  <template v-slot:prepend-inner>
                      <v-img src="../../assets/icon/feather/lock.svg" width="16" height="16" class="mt-1"></v-img>
                  </template>
                </v-text-field>
              </v-col>

              <v-col cols="12" class="mt-6">
                <v-text-field
                  placeholder="请再次输入密码"
                  hide-details
                  dense
                  outlined
                  flat
                  type="password"
                  color="grey"
                  class="pa-0 ma-0 body-2"
                  v-model="passwordConfirm"
                  :ripple="false"
                >
                  <template v-slot:prepend-inner>
                      <v-img src="../../assets/icon/feather/lock.svg" width="16" height="16" class="mt-1"></v-img>
                  </template>
                </v-text-field>
              </v-col>

              <v-col cols="12" class="mt-6 d-flex justify-center">
                 <v-btn
                    dark
                    color="#58BCB3"
                    elevation="0"
                    large
                  >提交
                  </v-btn>
              </v-col>
            </v-row>

             <v-row justify="center" class="mt-8 mb-10">
                <v-col cols="auto" >
                    <v-img src="../../assets/img/ji.png" width="111" height="111">
                    </v-img>
                </v-col>
            </v-row>
          </v-form>
        </v-card>
      </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'ResetPassword',

    data: () => ({
      email: 'test@qq.com',
      password: '',
      passwordConfirm: '',
    }),
  }
</script>

<style lang="scss" scoped>

.email {
  width: 400px;
}

.readonly {
  background-color: #F1F1F1;
}
</style>