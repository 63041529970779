<template>
    <v-card class="pa-4">
      <v-form ref="form" >
        <v-row dense no-gutters >
          <v-col>
            <div  class="subtitle-2">
              发表图片
            </div>
          </v-col>
          <v-spacer></v-spacer>
          <v-col>
              <v-icon class="float-right" small @click="close">mdi-close</v-icon>
          </v-col>
        </v-row>

        <v-row dense >
          <v-col cols="4" v-for="(item, i) of files" :key="i">
            <v-img :src="item.url"  aspect-ratio="1">
              <v-btn
                x-small
                absolute
                fab
                icon
                tile
                dark
                height="16"
                width="16"
                style="top:8px; right: 8px; background: rgba(0, 0, 0, 0.5)"
                @click="removeImage(i)"
              >
              <v-icon class="float-right" x-small>
                  mdi-close
                </v-icon>
              </v-btn>
            </v-img>
          </v-col>
        </v-row>

        <v-row dense no-gutters class="mt-4">
          <v-file-input
              style="display: none;"
              ref="file"
              hide-input
              accept="image/*"
              @change="changeFile"
              multiple
            >
          </v-file-input>
          <v-btn 
            elevation="0"
            block
            class="pa-8 text--disabled"
            @click="$refs.file.$refs.input.click()"
            v-if="files.length < 9"
          >
              <v-icon class="text--disabled" x-small>mdi-plus</v-icon>点击添加图片，最多9张
          </v-btn>
        </v-row>

        
        <v-textarea
          class="mt-4 body-2"
          placeholder="请输入图片描述，可为空"
          outlined
          flat
          dense
          no-resize
          hide-details
          color="grey"
          rows="3"
          v-model="textContent"
        ></v-textarea>

        <div class="mt-2 d-flex align-center">
          <emoji-picker @emoji="insertEmoji" class="emoji-container">
            <div slot="emoji-invoker" slot-scope="{ events: { click: clickEvent } }" @click.stop="clickEvent">
                <v-icon color="#58BCB3" style="cursor: pointer">mdi-emoticon-happy-outline</v-icon>
            </div>
            <div slot="emoji-picker" slot-scope="{ emojis, display }">
                <div class="emojis" :style="{ top: display.y + 'px', left: display.x + 'px' }">
                  <template v-for="(emojiGroup, category) in emojis">
                      <template v-for="(emoji, emojiName)  in emojiGroup">
                        <span
                          :key="category+emojiName"
                          :title="category+emojiName"
                          @click="insertEmoji(emoji)"
                        >{{ emoji }}</span>
                      </template>
                  </template>
                </div>
              </div>
          </emoji-picker>

          <v-checkbox
            v-model="privateValue"
            hide-details
            dense 
            class="pa-0 ml-4">
              <template v-slot:label>
                <div class="text-caption text--disabled">设置为仅自己可见</div>
              </template>
          </v-checkbox>

          <v-checkbox  
            v-if="user.is_star"
            v-model="starValue"
            @change="privateValue = false"
            hide-details
            dense 
            class="pa-0 ml-4">
            <template v-slot:label>
                <div class="text-caption text--disabled">设置为专栏内容</div>
            </template>
          </v-checkbox>
        </div>
          

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            
            dark
            color="#7A7E7E"
            elevation="0"
            @click="close"
          >
            取消
          </v-btn>
          <v-btn
           
            dark
            color="#58BCB3"
            elevation="0"
            @click="saveData"
            :loading="saving"
          >
            <v-img src="../../assets/handpainted/xiaolian_bai2.png"  width="20" height="20" contain></v-img>
            发布
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
</template>

<script>
  import axios from "axios";
  import { mapGetters } from 'vuex';

  export default {
    props: {

    },
    data: () => ({
      textContent: '',
      saving:false,
      privateValue: false,
      starValue: false,
      files: []
    }),

    computed: {
      ...mapGetters([
        'user'
      ]),
    },

    created: function () {
     
    },

    mounted () {
    },

    unmounted(){

    },

    methods: {
      changeFile(file) {
        if (Array.isArray(file)) {
          if(file.length > 9) {
            this.$snackbar.showMessage({ content: "最多9张图片" });
            return;
          }
            file.forEach((v)=>{
                this.uploadFile(v);
            }, this)
        } else {
            this.uploadFile(file);
        }
      },

      uploadFile(file){
        let form= new FormData();
        form.append('file', file)
        this.uploading = true;
        const that = this;
        axios.post('/api/upload', form, { headers: {'Content-Type': 'multipart/form-data' } } )
        .then((response) => {
            if (response.data.code === 0) {
                this.files.push(response.data.data);
            } else {
                this.$snackbar.showMessage({ content: response.data.msg });
            }
        })
        .catch(function () {})
        .then(function () {
            that.uploading = false;
        });
      },

      insertEmoji(emoji) {
        this.textContent += emoji;
      },

      close() {
        this.$emit('closeEvent');
      },

      removeImage(i) {
        this.files.splice(i, 1);
      },

      saveData() {
        if (!this.$refs.form.validate()) {
          this.$snackbar.showMessage({ content: '输入字段有误' });
          return;
        }

        const that = this;
        that.saving = true;
        axios.post("/api/post/add", {
            content: this.textContent,
            type: 3,
            pids: this.files.map(item => item.pid).join(","),
            private: this.privateValue ? 1 : 0,
            star: this.starValue ? 1 : 0,
          }
        ).then((response) => {
            if (response.data.code === 0) {
              this.$snackbar.showMessage({ content: '发布成功' });
              
              // reset form value
              this.textContent = '';
              this.files = [];
              this.private = false;
              this.$router.push({ path: `/post/detail/${response.data.data.id}` });
            } else {
              this.$snackbar.showMessage({ content: response.data.msg })
            }
        })
        .catch(function () {})
        .then(function () {
            that.saving = false;
        });
      }
    },
  };
</script>

<style lang="scss" scoped>
</style>