<template>
  <div>
    <v-container style="background-color: white;" class="mt-4 pa-5" align="center">
      <v-row>
        <v-col>
          <router-link class="primary-link ml-2" :to="`/author/detail/${authorId}`">
            <v-avatar size="28" >
              <v-img v-if="authorAvatar.length > 0" :src="authorAvatar" ></v-img> 
              <v-img v-else src="../../assets/icon/feather/user.svg"  width="16" height="16" contain></v-img>
            </v-avatar>
          </router-link>
          <router-link class="primary-link ml-2" :to="`/author/detail/${authorId}`">{{author}}</router-link>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="d-flex align-center justify-end">
          <div class="text-caption text--disabled float-right mt-1">{{date}}</div>
        </v-col>
      </v-row>

      <h5 class="font-weight-bold text-h5 mt-4" v-if="title.length > 0">{{title}}</h5>

      <template v-if="pics.length > 0">
        <photoswipe auto rotate>
          <v-row dense class="mt-4">
            <v-col cols="12" v-for="(item, i) in pics" :key="i" >
              <img :src="item" style="max-width: 100%"/>
            </v-col>
          </v-row>
        </photoswipe>
      </template>

      <div v-html="content" class="mt-4 body-2 "  v-if="content && content.length > 0"></div>

      <v-row dense justify="space-between" class="mt-8">
        <div>
          <v-btn
            dark
            color="#58BCB3"
            elevation="0"
            v-if="hasLiked"
            @click="unLike"
          >
            <v-img class="icon" src="../../assets/handpainted/dianzan_bai.png" width="14" height="14" contain></v-img>
            赞<template v-if="loveCount">{{loveCount}}</template>
          </v-btn>
          <v-btn
            v-else
            elevation="0"
            @click="doLike"
          >
            <v-img class="icon" src="../../assets/handpainted/dianzan_hui.png" width="14" height="14" contain></v-img>
            赞<template v-if="loveCount">{{loveCount}}</template>
          </v-btn>
          <v-btn
            text
            v-if="(authorId > 0 && authorId == user.uid) || user.is_super"
            color="red"
            elevation="0"
            class="ml-2"
            @click="deletePost"
          >
            删除
          </v-btn>
        </div>

         <v-btn
          dark
          color="#58BCB3"
          elevation="0"
          @click="showCommentDialog"
        >
          <v-img class="icon" src="../../assets/handpainted/pinglun_bai.png" width="14" height="14" contain></v-img>
          评论<template v-if="commentCount">{{commentCount}}</template>
        </v-btn>
      </v-row>
    </v-container>

    <v-container style="background-color: white;" class="my-4 pa-5">
      <div>{{commentCount}} 条评论</div>
      <v-list three-line v-if="comments.length > 0">
         <template v-for="(item, index) in comments">
            <v-list-item :key="index" class="comment-item">
              <v-list-item-avatar>
                  <v-img v-if="item.avatar && item.avatar.length" :src="item.avatar"></v-img> 
                  <v-img v-else src="../../assets/icon/feather/user.svg"  width="24" height="24" contain></v-img>
              </v-list-item-avatar>
              <v-list-item-content>

              <v-list-item-title>
                <div class="text-caption text--disabled">{{ item.user_name }}
                    <div class="text-caption text--disabled float-right"> {{ item.date }} </div>
                </div>
              </v-list-item-title>

              <v-list-item-subtitle class="comment-content mt-2">
                <!-- <span class="text--disabled">回复:</span> -->
                {{ item.content }}
              </v-list-item-subtitle>

               <v-list-item-subtitle  class="mt-2">
                <!-- <v-btn
                  small
                  dark
                  color="#58BCB3"
                  elevation="0"
                  class="px-3 mr-2"
                  @click="doLikeComment(item.id, index)"
                >
                  赞
                </v-btn> -->

                <v-btn
                  v-if="item.uid == authorId"
                  small
                  elevation="0"
                  class="mr-2"
                  @click="replyId = item.id; replyDialog=true;"
                >
                  回复
                </v-btn>

                 <v-btn
                  v-if="item.uid == uid || user.is_super"
                  small
                  dark
                  text
                  color="red"
                  elevation="0"
                  @click="deleteComment(item.id, index)"
                >
                  删除
                </v-btn>
              </v-list-item-subtitle>
             </v-list-item-content>
            </v-list-item>
         </template>
      </v-list>

      <div class="text-center mt-2" v-if="totalPage > 1">
        <v-pagination
          v-model="pageNo"
          circle
          color="#58BCB3"
          :length="totalPage"
          total-visible="5"
          class="comment-pagination"
          @input="loadComments"
        ></v-pagination>
      </div>
      <div v-if="commentCount == 0" class="mt-2">
        <p class="text--disabled text-center text-caption">暂无评论</p>
      </div>

    </v-container>

    <v-dialog
      v-model="commentDialog"
      persistent
      max-width="600"
    >
      <comment-form @closeEvent="commentDialog = false" :href="commentUrl" @successEvent="reloadComment"></comment-form>
    </v-dialog>

     <v-dialog
      v-model="replyDialog"
      persistent
      max-width="600"
    >
      <reply-form @closeEvent="replyDialog = false" :href="replyUrl" @successEvent="reloadComment"></reply-form>
    </v-dialog>

    <fab-btn home></fab-btn>
  </div>
</template>

<script>
import axios from "axios";
import CommentForm from "../widgets/CommentForm.vue";
import ReplyForm from "../widgets/ReplyForm.vue";
import FabBtn from "../widgets/FabBtn.vue";
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      id: 0,
      title: "",
      content: "",
      date: "",
      author: "",
      authorAvatar: "",
      authorId: 0,
      loveCount: 0,
      commentCount: 0,
      hasLiked: false,
      pics: [],
      comments: [],
      pageNo: 1,
      pageSize: 20,
      commentDialog: false,
      replyDialog: false,
      replyId: 0,
      has_liked: false
    }
  },


  components: {
    CommentForm,
    ReplyForm,
    FabBtn,
  },

  computed: {
    totalPage() {
      return Math.ceil(this.commentCount / this.pageSize);
    },
    commentUrl() {
      return `/api/post/${this.id}/comment/add`;
    },
    replyUrl() {
      return `/api/post/${this.id}/comment/${this.replyId}/reply`;
    },
    ...mapGetters([
      'uid',
      'user'
    ]),
  },

  created() {
    this.id = parseFloat(this.$route.params.id);
    this.loadPost();
    this.loadComments();
  },

  beforeRouteUpdate(to, from, next) {
      this.id = parseFloat(to.params.id);
      this.loadData();
      this.loadComments()
      next();
  },

  methods: {
    loadPost () {
      axios.get(`/api/post/${this.id}`)
      .then((response) => {
        const data = response.data.data;
        this.title = data.title;
        this.content = data.content;
        this.date = data.create_time.slice(0, 10);
        this.author = data.user.name;
        this.authorId = data.user.id;
        this.authorAvatar = data.user.avatar == null ? '' : data.user.avatar;
        this.loveCount = data.like_count;
        this.hasLiked = data.has_liked;
        this.pics = data.imgs;
      })
      .catch(function () {})
      .then(function () {
      });
    },

    reloadComment() {
      this.replyDialog = false;
      this.commentDialog = false;
      this.pageNo = 1;
      this.loadComments();
    },

    deletePost() {
      axios.post(`/api/post/${this.id}/delete`)
      .then((response) => {
        if (response.data.code === 0) {
          this.$snackbar.showMessage({ content: '删除成功' });
          this.$router.push({ path: `/` });
        } else {
          this.$snackbar.showMessage({ content: response.data.msg })
        }
      })
      .catch(function () {})
      .then(function () {
      }); 
    },

    loadComments() {
      axios.get(`/api/post/${this.id}/comment`, {
        params: {page_no: this.pageNo, page_size: this.pageSize },
      })
      .then((response) => {
        this.comments = response.data.data.items;
        this.commentCount = response.data.data.total_count;
      })
      .catch(function () {})
      .then(function () {
      }); 
    },

    deleteComment(commentid, index) {
      axios.post(`/api/post/${this.id}/comment/${commentid}/delete`)
      .then(response => {
          if (response.data.code === 0) {
              this.comments.splice(index, 1);
              this.commentCount -= 1;
          } else {
            this.$snackbar.showMessage({ content: response.data.msg })
          }
      });
    },

    showCommentDialog() {
       if  (this.uid <=0 ) {
        this.$snackbar.showMessage({ content: "请先登录" });
        return;
      }
      this.commentDialog = true;
    },

    doLike() {
      if  (this.uid <=0 ) {
        this.$snackbar.showMessage({ content: "请先登录" });
        return;
      }

      axios.post(`/api/post/${this.id}/addlike`)
      .then(response => {
          if (response.data.code === 0) {
            this.hasLiked = true;
            this.loveCount += 1;
          } else {
            this.$snackbar.showMessage({ content: response.data.msg })
          }
      });
    },

    unLike() {
      axios.post(`/api/post/${this.id}/dellike`)
      .then(response => {
        if (response.data.code === 0) {
          this.hasLiked = false;
          if (this.loveCount > 1)this.loveCount -= 1;
        } else {
          this.$snackbar.showMessage({ content: response.data.msg })
        }
      });
    },

    doLikeComment(commentid, index) {
      if  (this.uid <=0 ) {
        this.$snackbar.showMessage({ content: "请先登录" });
        return;
      }

      axios.post(`/api/post/${this.id}/comment/${commentid}/like`)
      .then(response => {
          if (response.data.code === 0) {
            console.log(index)
          } else {
            this.$snackbar.showMessage({ content: response.data.msg })
          }
      });
    },

    unLikeComment(commentid, index) {
      axios.post(`/api/post/${this.id}/comment/${commentid}/dellike`)
      .then(response => {
        if (response.data.code === 0) {
           console.log(index)
          // this.hasLiked = false;
          // if (this.loveCount > 1)this.loveCount -= 1;
        } else {
          this.$snackbar.showMessage({ content: response.data.msg })
        }
      });
    }
  },
}
</script>

<style lang="scss" scoped>
.v-list--three-line .v-list-item .v-list-item__subtitle.comment-content {
  -webkit-line-clamp: unset;
}

.preview {
  max-height: 90vh;
  max-width: 90vw;
  position: relative;
}

</style>