<template>
  <v-container>
    <v-container>
        <router-link :to="{ path: `/search/post` }" class="text-decoration-none subtitle-1 tab-link">
            相关内容
        </router-link>
        <router-link :to="{ path: `/search/user` }" class="text-decoration-none subtitle-1 ml-5 tab-link">
            相关用户
        </router-link>
    </v-container>
    <router-view></router-view>

    <fab-btn home></fab-btn>
  </v-container>
</template>

<script>
  import FabBtn from "../widgets/FabBtn.vue";

  export default {
    data: () => ({
    }),

    components: {
      FabBtn
    },

    created() {
    },

    beforeRouteUpdate(to, from, next) {
      next();
    },
  };
</script>