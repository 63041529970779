<template>
  <v-container class="pa-9 my-9 edit-container">
    <v-form ref="form" >
      <v-text-field
          v-model="title"
          placeholder="请输入标题…"
          solo
          flat
          hide-details
          class="ml-n3 font-weight-bold text-h5 mt-2"
      >
      </v-text-field>

      <div id="editor" class="mt-2"></div>

      <div class="mt-2 d-flex">
        <v-checkbox  
          v-model="privateValue"
          hide-details
          dense 
          @change="starValue = false"
          class="pa-0">
          <template v-slot:label>
              <div class="text-caption text--disabled">设置为仅自己可见</div>
          </template>
        </v-checkbox>
         <v-checkbox  
          v-if="user.is_star"
          v-model="starValue"
          @change="privateValue = false"
          hide-details
          dense 
          class="pa-0 ml-4">
          <template v-slot:label>
              <div class="text-caption text--disabled">设置为专栏内容</div>
          </template>
        </v-checkbox>
      </div>

      <div class="mt-2 d-flex align-center justify-center">
        <v-btn
          dark
          color="#58BCB3"
          elevation="0"
          @click="saveData"
          :loading="saving"
          >
           <v-img src="../../assets/handpainted/niao_bai.png"  width="20" height="20" contain></v-img>
          发布长文
        </v-btn>
      </div>
    </v-form >
  </v-container>
</template>

<script>
// 引入 wangEditor
import wangEditor from 'wangeditor'
import axios from "axios"; 
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      title: '',
      editor: null,
      editorData: '',
      saving:false,
      privateValue: false,
      starValue: false
    }
  },

  computed: {
    ...mapGetters([
      'user'
    ]),
  },

  mounted() {
    const editor = new wangEditor(`#editor`);
    editor.config.uploadImgServer = '/api/upload/weditor';
    editor.config.uploadImgMaxLength = 1;
    editor.config.uploadFileName = 'file';
    editor.config.withCredentials = true
    editor.config.uploadImgHeaders = {
      Authorization:  "Bearer " + this.user.token
    }

    // 配置 onchange 回调函数，将数据同步到 vue 中
    editor.config.onchange = (newHtml) => {
       this.editorData = newHtml
    }

    editor.config.height = 350
    
    // 创建编辑器
    editor.create()
    this.editor = editor
  },

  methods: {
    getEditorData() {
      // 通过代码获取编辑器内容
      this.editor.txt.html()
    },

    saveData() {
        if (!this.$refs.form.validate()) {
          this.$snackbar.showMessage({ content: '输入字段有误' });
          return;
        }

        const that = this;
        that.saving = true;
        axios.post("/api/post/add", {
            content: this.editorData,
            title: this.title,
            type: 2,
            private: this.privateValue ? 1 : 0,
            star: this.starValue ? 1 : 0,
          }
        ).then((response) => {
            if (response.data.code === 0) {
              this.$snackbar.showMessage({ content: '发布成功' });
              this.$router.push({ path: `/post/detail/${response.data.data.id}` });
            } else {
              this.$snackbar.showMessage({ content: response.data.msg })
            }
        })
        .catch(function () {})
        .then(function () {
            that.saving = false;
        });
      }
    },

    beforeDestroy() {
      // 调用销毁 API 对当前编辑器实例进行销毁
      this.editor.destroy()
      this.editor = null
    }
  }
</script>

<style lang="scss" scoped>
  .edit-container {
      background-color: white;
      min-height: 80vh;

      .v-text-field > .v-input__control > .v-input__slot {
          padding: 0;
      }
  }
</style>