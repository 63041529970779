<template>
  <div>
    <v-form ref="form" >
      <v-row no-gutters class="mx-7 mt-6">
        <v-col cols="12" class="d-flex justify-center ">
            <v-file-input
              style="display: none;"
              ref="file"
              hide-input
              accept="image/*"
              @change="changeFile"
            >
            </v-file-input>
            <v-avatar style="position:relative;" height="100" width="100"  @click="$refs.file.$refs.input.click()" v-ripple>
              <v-img v-if="avatar"  :src="avatar" contain ></v-img>
              <div  class="avatar-tip" style="width:100px; height: 100px">
                <div class="text-tip">
                  <v-img src="../../assets/icon/feather/camera-white.svg" width="24" height="24" contain></v-img>
                  <div style="font-size: 12px;">修改头像</div>
                </div>
              </div>
            </v-avatar>
        </v-col>
        <v-col class="d-flex justify-center mt-2">
          <div style="font-size: 12px;color:#707070;">建议尺寸：100*100</div>
        </v-col>
        <v-col cols="12" class="mt-6">
          <v-text-field
            placeholder="请设置您的昵称"
            hide-details
            dense
            outlined
            flat
            v-model="username"
            color="grey"
            class="pa-0 ma-0 body-2"
            :ripple="false"
          >
            <template v-slot:prepend-inner>
                <v-img src="../../assets/icon/feather/user.svg" width="16" height="16" class="mt-1"></v-img>
            </template>
          </v-text-field>
        </v-col>

        <v-col cols="12" class="mt-6 d-flex align-center ">
          <v-radio-group
            v-model="gender"
            hide-details
            dense
            row
            class="pa-0 ma-0"
          >
            <v-radio
              label="男性"
              :value="1"
              color="#58BCB3"
            ></v-radio>
            <v-radio
              label="女性"
              :value="2"
              color="#58BCB3"
            ></v-radio>
          </v-radio-group>
        </v-col>

        <v-col cols="12" class="mt-6">
          <v-menu
            ref="menu"
            v-model="datepickerMenu"
            :close-on-content-click="false"
            :return-value.sync="birthday"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                placeholder="请选择您的生日"
                hide-details
                dense
                outlined
                flat
                color="grey"
                class="pa-0 ma-0 body-2"
                v-model="birthday"
                :ripple="false"
                readonly
                v-bind="attrs"
                v-on="on"
              >
                <template v-slot:prepend-inner>
                  <v-img src="../../assets/icon/feather/calendar.svg" width="16" height="16" class="mt-1"></v-img>
                </template>
                <template v-slot:append>
                  <v-img src="../../assets/icon/feather/chevron-down.svg" width="16" height="16" class="mt-1"></v-img>
                </template>
              </v-text-field>
            </template>
            <v-date-picker
              v-model="birthday"
              no-title
              scrollable
              :first-day-of-week="0"
              locale="zh-cn"
            >
              <v-spacer></v-spacer>
              <v-btn
                text
                color="primary"
                @click="menu = false"
              >
                取消
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs.menu.save(birthday)"
              >
                确定
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>

          <v-col cols="12" class="mt-6">
          <v-text-field
            placeholder="请输入您所在地区"
            hide-details
            dense
            outlined
            flat
            color="grey"
            class="pa-0 ma-0 body-2"
            v-model="addr"
            :ripple="false"
          >
            <template v-slot:prepend-inner>
              <v-img src="../../assets/icon/feather/map-pin.svg" width="16" height="16" class="mt-1"></v-img>
            </template>
          </v-text-field>
        </v-col>
      </v-row>
      
      <v-card-actions class="mt-4">
        <v-spacer></v-spacer>
        <v-btn
          
          dark
          color="#7A7E7E"
          elevation="0"
          @click="close"
        >
          取消
        </v-btn>
        <v-btn
          
          dark
          color="#58BCB3"
          elevation="0"
          @click="saveData"
          :loading="saving"
        >
          提交
        </v-btn>
      </v-card-actions>
    </v-form>
  </div>
</template>

<script>
  import axios from "axios";
  import { mapGetters } from 'vuex';

  export default {
    data: () => ({
      datepickerMenu: false,
      username: '',
      avatar: '',
      birthday: '',
      addr: '',
      gender: 0,
      saving: false,
    }),

    computed: {
      ...mapGetters([
        'uid',
        'user'
      ]),
    },

    created: function () {
      this.loadUser()
    },

    mounted () {
    },

    unmounted(){

    },

    methods: {

      changeFile(file) {
        if (Array.isArray(file)) {
          file.forEach((v)=>{
              this.uploadFile(v);
          }, this)
        } else {
            this.uploadFile(file);
        }
      },

      uploadFile(file){
        let form= new FormData();
        form.append('file', file)
        this.uploading = true;
        const that = this;
        axios.post('/api/upload/avatar', form, { headers: {'Content-Type': 'multipart/form-data' } } )
        .then((response) => {
            if (response.data.code === 0) {
                this.avatar = response.data.data.url;
            } else {
                this.$snackbar.showMessage({ content: response.data.msg });
            }
        })
        .catch(function () {})
        .then(function () {
            that.uploading = false;
        });
      },

      close() {
        this.$emit('closeEvent');
      },

      loadUser() {
        axios.get(`/api/u/${this.uid}`)
          .then((response) => {
              this.avatar = response.data.data.avatar;
              this.addr = response.data.data.area;
              this.username = response.data.data.name;
              this.gender = response.data.data.gender;
              this.birthday = response.data.data.birthday;
          })
          .catch(function () {})
          .then(function () {
        });
      },

      saveData() {
        if (!this.$refs.form.validate()) {
          this.$snackbar.showMessage({ content: '输入字段有误' });
          return;
        }

        const that = this;
        that.saving = true;
        axios.post("/api/user/edit", {
            name: this.username,
            gender: this.gender,
            birthday: this.birthday,
            area: this.addr,
            avatar: this.avatar,
          }
        ).then((response) => {
            if (response.data.code === 0) {
              this.$snackbar.showMessage({ content: '修改成功' });

              let newUser = {...this.user, username:this.username, avatar: this.avatar};
              this.$store.dispatch("user", newUser);

              // reset form value
              this.close()
            } else {
              this.$snackbar.showMessage({ content: response.data.msg })
            }
        })
        .catch(function () {})
        .then(function () {
            that.saving = false;
        });
      }
    },
  };
</script>

<style lang="scss" scoped>
.custom-tab {
  min-width: 0px;
}

.avatar-tip {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  

  .text-tip {
    color: white;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>