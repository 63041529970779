<template>
  <div>
    <Snackbar></Snackbar>
    <v-app>
      <v-app-bar v-if="appBar" dense  app color="white" class="custom-app-bar pa-0">
        <v-container style="position: relative;">
            <v-row dense align="center">
              <v-col>
                <template v-if="searchBar">
                  <div class="d-flex justify-start">
                    <div class="searchBar d-flex align-center pa-0" :class="{'in-search': inSearch}">
                      <v-btn v-if="!inSearch" small icon @click="inSearch = true" class="float-left ml-2">
                        <v-img class="icon" src="./assets/icon/feather/search.svg"  width="20" height="20" contain></v-img>
                      </v-btn>

                      <v-text-field
                        v-else
                        placeholder="搜索您感兴趣的内容"
                        hide-details
                        dense
                        solo
                        flat
                        rounded
                        background-color="#EEEEEE"
                        color="grey"
                        class="pa-0 ma-0 body-2 "
                        v-model="searchKey"
                        :ripple="false"
                        v-click-outside="hideSearchInput"
                        @keydown="doSearch"
                        clearable
                      >
                        <v-img   @click="doSearch"  slot="append" class="icon" src="./assets/icon/feather/search.svg"  width="20" height="20" contain></v-img>
                      </v-text-field>
                    </div>
                    <template v-if="$route.path.includes('/search')">
                      <router-link :to="`/`" class="text-decoration-none subtitle-2 ml-2" style="line-height:38px;">取消</router-link>
                    </template>
                  </div>
                </template>

                <v-btn v-else icon @click="$router.go(-1)" class="float-left" small>
                  <v-img class="icon" src="./assets/icon/feather/arrow-left.svg"  width="20" height="20" contain></v-img>
                </v-btn>
              </v-col>
              <v-col :class="{'d-none': inSearch, 'd-md-flex': inSearch, 'd-lg-flex': inSearch, 'd-xl-flex': inSearch,}" cols="auto">
                <v-avatar size="28" class="float-right">
                  <template v-if="uid > 0">
                    <router-link :to="`/author/detail/${uid}`" class="text-decoration-none">
                      <v-img v-if="user.avatar.length" :src="user.avatar" width="40" height="40"></v-img> 
                      <v-img v-else src="./assets/icon/feather/user.svg"  width="20" height="20" contain></v-img>
                    </router-link>
                  </template>
                  <template v-else>
                     <router-link to="/login" class="text-decoration-none">
                      <v-img src="./assets/icon/feather/user.svg"  width="20" height="20" contain></v-img>
                     </router-link>
                  </template>
                </v-avatar>
              </v-col>
            </v-row>
            <div class="logo" :class="{'d-none': inSearch, 'd-md-flex': inSearch, 'd-lg-flex': inSearch, 'd-xl-flex': inSearch,}">
                <!-- <v-img src="./assets/img/apark.png" height="30px" contain></v-img> -->
            </div>
        </v-container>
      </v-app-bar>

      <v-main>
        <router-view></router-view>
      </v-main>

      <v-footer dark padless v-if="appFooter">
        <v-card
          class="flex"
          flat
          tile
        >
          <v-card-text class="py-2 text-center text-caption">
            <span> &copy;  倾听树洞</span> <a class="white--text ml-2 text-decoration-none" href="mailto:webmaster@krisandmeigeni.com">给管理员留言</a>
          </v-card-text>
        </v-card>
      </v-footer>
    
    </v-app>
    <div class="v-application preloader" v-if="loadingConfig">
      <v-progress-linear color="black" indeterminate  class="progress" stream></v-progress-linear>
    </div>
         
 </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Snackbar from './components/widgets/Snackbar';
import axios from "axios";

export default {
  name: 'App',
  data: () => ({
    key: '',
    inSearch: false,
    loadingConfig: true

  }),

  components: {
    Snackbar,
  },

  computed: {
    ...mapGetters([
      'appBar',
      'appFooter',
      'searchBar',
      'uid',
      'user',
      'searchKeyword',
      'searchType'
    ]),
    searchKey: {
      get: function() {
        return this.searchKeyword;
      },
      set: function(v) {
        this.$store.commit("searchKeyword", v); 
      }
    }
  },

  created() {
    document.body.style.overflow="hidden";
    document.body.style.height="100vh";
    this.searchKey = this.$route.query.k;
    this.loadConfig();
  },

  beforeRouteUpdate(to, from, next) {
    this.searchKey = this.$route.query.k;
    next();
  },

  methods: {
    hideSearchInput() {
      if (this.searchKey != null && this.searchKey.length == 0) {
          this.inSearch = false;
      } else  if (this.searchKey == null) {
          this.inSearch = false;
      }
    },
    doSearch(e) {
      if (e.type == 'keydown') {
        if(e.keyCode != 13) return;
      }

      if (this.searchKey.length > 0) {
        if (this.searchType == 'user') {
          //query 参数只是修正相同url不能跳转的问题
          this.$router.push({ path: '/search/user', query: {k: this.searchKey} });
        } else {
           //query 参数只是修正相同url不能跳转的问题
          this.$router.push({ path: '/search/post',  query: {k: this.searchKey}  });
        }
      }
    },

    //加载网站个性化设置 @todo
    loadConfig() {
        const that = this;

        axios.get(`/api/config`)
        .then((response) => {
          this.$store.commit("config", response.data.data); 
        })
        .catch(function () {})
        .then(function () {
          that.loadingConfig = false;
          document.body.style.overflow="visible";
          document.body.style.height="initial";
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.searchBar {
  border-radius: 40px;
  &.in-search {
    background-color: #EEEEEE;
  }
  max-width: 300px;
}
.logo {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.preloader {
  position: fixed; 
  top: 0; 
  bottom: 0; 
  right: 0; 
  left: 0;
  z-index:9; 
  height: 100vh; 
  width: 100vw; 
  background-color: white;
  .progress {
    max-width: 150px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>